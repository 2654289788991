import { common } from "./common";
import { config } from "./config";
// const
let refreshCall = false;
const base_url = config.api.url + "app";
const auth_url = base_url + "/auth";
const ids_auth_url = config.api.url + "ids";
const student_url = base_url + "/student";
const agent_url = base_url + "/agent";
const common_url = config.api.url + "common";

// services
const services = {
  // auth
  auth_login: auth_url + "/login",
  auth_otp_verify: auth_url + "/otp/verify",
  auth_otp_generate: auth_url + "/otp/generate",
  auth_password_reset: auth_url + "/password/reset",

  //ids auth
  ids_login: ids_auth_url + "/login",
  ids_forgot_password: ids_auth_url + "/forgot/password",
  ids_sign_up: ids_auth_url + "/register",
  ids_otp_verify: ids_auth_url + "/verify/otp",
  ids_otp_reset: ids_auth_url + "/reset/otp",
  ids_otp_reset_password: ids_auth_url + "/reset/password",
  ids_refresh_token: ids_auth_url + "/refresh/token",

  ids_update_profile: ids_auth_url + "/update/profile",
  ids_update_password: ids_auth_url + "/update/password",

  profile_password_reset: base_url + "/profile/password/reset",

  // student
  student_register: student_url + "/register",
  student_profile: student_url + "/profile",
  student_info: student_url + "/info",
  student_connect: student_url + "/connect",

  // agent
  agent_register: agent_url + "/register",
  agent_profile: agent_url + "/profile",
  agent_plan: agent_url + "/plans",
  agent_payment: agent_url + "/payments",
  agent_student: agent_url + "/student",
  agent_student_info: agent_url + "/student/info",
  agent_application1: agent_url + "/application",
  agent_dashboard: agent_url + "/dashboard",
  agent_connect: agent_url + "/connect",
  agent_pdf_process: agent_url + "/agreement",
  agent_pdf_download: agent_url + "/agreement/download",
  subagent: agent_url + "/sub-agents",

  // promocode
  promoCode: base_url + "/promocodes",

  // promo
  promo: base_url + "/promocodes",

  //agents
  agents: base_url + "/agents",
  coordinator_agent_students: base_url + "/coordinator/agents/students",
  coordinator_agent_applications: base_url + "/coordinator/agents/applications",

  //promoter
  promoter_students: base_url + "/agents/students",
  promoter_application: base_url + "/agents/applications",
  promoter_students_info: base_url + "/agents/student",
  promoter_application_info: base_url + "/agents/application",
  promoter_application_status: base_url + "/coordinator/app/status",
  promoter_application_support: base_url + "/coordinator/app/support",

  //notification
  notification: base_url + "/notification",
  // university
  university: base_url + "/university",

  // course
  course: base_url + "/course",
  course_tag: base_url + "/course/tag",
  course_pickup: base_url + "/course/pickup",
  course_change: base_url + "/course/change",
  intake_change: base_url + "/intake/change",
  // subject
  subject: base_url + "/subject",

  // plan
  plan: common_url + "/plan",

  // application
  application: base_url + "/application",
  application_status: base_url + "/application/status",
  application_support: base_url + "/application/support",
  application_order: base_url + "/application/order",
  application_payment: base_url + "/application/payment",
  application_profile: base_url + "/application/profile",

  // payment
  payment_info: base_url + "/payment/info",
  payment_create: base_url + "/payment/create",

  // promo
  promo: base_url + "/promocode",

  // common
  common_grade: common_url + "/grade",
  common_data: common_url + "/data",
  common_country: common_url + "/country",
  common_state: common_url + "/states",
  common_city: common_url + "/city",
  common_city_v2: common_url + "/city/v2",

  common_upload: common_url + "/upload",
  common_email_check: common_url + "/email/check",
  common_sales_person: common_url + "/salesperson",
};

// api call
export const api = {
  async call(data = {}, response, final) {
    getData(data);
    // server side rendering
    if (data.ssr) {
      let myPromise = new Promise(function (resolve, reject) {
        fetch(data.url, getOptions(data))
          .then(async (response) => {
            return await response.text().then((data) => ({
              status: response.status,
              data: data,
            }));
          })
          .then((result) => {
            //console.log({ result }, "");
            if (validateData(result)) {
              try {
                result.data = JSON.parse(result.data);
              } catch (e) {
                result.data = {
                  error: e.toString(),
                };
              }
              notify(data, result);
              resolve(result);
            }
          })
          .catch((err) => {
            let result = {
              status: 404,
              data: {
                error: err.toString(),
              },
            };
            notify(data, result);
            resolve(result);
          });
      });
      return myPromise;
    } else {
      // client side rendering
      fetch(data.url, getOptions(data))
        .then(async (response) => {
          //refresh token
          if (response.status === 401) {
            let refreshTokenResult = await refreshAccessToken();

            if (refreshTokenResult.status === 200) {
              let _data = await refreshTokenResult.json();
              //console.log({ _data });
              common.localSet("authData", common.crypt(_data, true));
              const newAccessToken = _data.accessToken;
              const options = getOptions(data);
              options.headers.Authorization = `Bearer ${newAccessToken}`;
              return fetch(data.url, options).then(async (resp) => {
                return resp.text().then((data) => ({
                  status: resp.status,
                  data: data,
                }));
              });
            } else {
              console.log("fetch expired");
              document.cookie =
                "=; expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=student.pickauni.com; path=/;";
              document.cookie =
                "=; expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=pickauni.com; path=/;";
              document.cookie =
                "authData=; expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=pickauni.com; path=/;";
              document.cookie =
                "authData=; expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=student.pickauni.com; path=/;";
              // common.redirect();
              document.cookie = "=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
              setTimeout(() => {
                window.location.href = "https://www.pickauni.com/auth/student";
                //common.redirect("login");
              }, 600);
              return {
                status: 401,
              };
            }
          } else {
            if (data?.responseType == "file") {
              return response.blob();
            } else {
              return response.text().then((data) => ({
                status: response.status,
                data: data,
              }));
            }
          }
        })
        .then((result) => {
          if (data?.responseType == "file") {
            const url = URL.createObjectURL(result);
            const a = document.createElement("a");
            let fileName = data?.fileName ?? "pickauni";
            a.style.display = "none";
            a.href = url;
            a.download = `${fileName}`;
            // a.download = `${fileName}_${moment().format("YYYY_MM_DD_HH:mm")}.${
            //   data?.extensions ?? "pdf"
            // }`; // replace with your desired filename and extension
            document.body.appendChild(a);
            a.click();
            response(result);
          } else {
            if (validateData(result)) {
              try {
                if (result.status == 204) {
                  result.data = {};
                } else {
                  result.data = JSON.parse(result.data);
                }
              } catch (e) {
                result.data = {
                  error: e.toString(),
                };
              }
              notify(data, result);
              response(result);
            }
          }
        })
        .catch((err) => {
          //console.log({ err });
          let result = {
            status: 404,
            data: {
              error: err.toString(),
            },
          };
          notify(data, result);
          response(result);
        })
        .finally(() => {
          if (typeof final !== "undefined") {
            final();
          }
        });
    }
  },
};

// support
const getData = (data) => {
  data.repeat = data.repeat || false;
  if (!data.repeat) {
    data.auth = data.auth || "token";
    data.type = data.type || "standard";
    data.method = data.method || "POST";
    data.cType = data.cType || 1;
    data.query = data.query || "";
    data.body = data.body || "";
    data.keyQuery = data.keyQuery || "";
    data.notify = data.notify ?? true;
    // set url
    if (data.type == "third") {
      data.url = data.url + data.query;
    } else {
      data.url = services[data.url] + data.query;
      if (data.keyQuery != "") {
        for (var key in data.keyQuery) {
          data.url = data.url.replace(`:${key}`, data.keyQuery[key]);
        }
      }
    }
    // set body
    if (data.body) {
      if (data.cType == 1) {
        data.body = data.body ? JSON.stringify(data.body) : "";
      } else if (data.cType == 2) {
        let bodyParam = [];
        for (var property in data.body) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(data.body[property]);
          bodyParam.push(encodedKey + "=" + encodedValue);
        }
        data.body = bodyParam.join("&");
      }
    }
  }
};

function getOptions(data) {
  let reqOptions = {
    method: data.method,
    headers: getHeaders(data),
  };

  if (data.body) {
    reqOptions.body = data.body;
  }
  return reqOptions;
}

function getHeaders(data) {
  // default
  let headers = {};

  // content types
  let contentTypes = {
    1: "application/json",
    2: "application/x-www-form-urlencoded",
    3: "application/vnd.oracle.adf.action+json",
    4: "application/vnd.oracle.adf.resourceitem+json",
    5: "application/vnd.oracle.adf.batch+json",
    6: "multipart/form-data",
  };
  if (data.cType !== 6) {
    headers["Content-Type"] = contentTypes[data.cType];
  }

  // extra content types
  let moreHeaderList = {
    rfv: "REST-Framework-Version",
  };
  if (data.moreHead) {
    for (var item in data.moreHead) {
      headers[moreHeaderList[item]] = data.moreHead[item];
    }
  }

  // authentication
  if (data.auth == "token") {
    let authData = common.getAuth();
    headers.Authorization = "Bearer " + authData?.accessToken; //.token;
  } else if (data.auth == "temp") {
    let tempData = common.tempDataGet();
    headers.Authorization = "Bearer " + tempData?.accessToken;
  } else if (data.auth == "basic") {
    headers.Authorization =
      "Basic " + btoa(data.credentials.param1 + ":" + data.credentials.param2);
  }

  return headers;
}

const validateData = (result) => {
  //console.log({ result });
  if (config.api.isExpired != "" && result.status == config.api.isExpired) {
    console.log("validate expired");
    document.cookie =
      "=; expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=student.pickauni.com; path=/;";
    document.cookie =
      "=; expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=pickauni.com; path=/;";
    document.cookie =
      "authData=; expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=pickauni.com; path=/;";
    document.cookie =
      "authData=; expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=student.pickauni.com; path=/;";
    // common.redirect();
    document.cookie = "=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
    setTimeout(() => {
      window.location.href = "https://www.pickauni.com/auth/student";
      //common.redirect("login");
    }, 600);
    return false;
  } else {
    return true;
  }
};

const notify = (data, result) => {
  if (data.notify) {
    if (![200, 500, 400, 401].includes(result.status)) {
      common.notify("E", result.data.error);
    }
  }
};

// rToken
const refreshAccessToken = async () => {
  try {
    let authData = common.getAuth();
    const response = await fetch(services["ids_refresh_token"], {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        refreshToken: authData?.refreshToken,
      }),
    });

    return response;
  } catch (error) {
    //console.log(error);
  }
};
